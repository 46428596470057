import { DM_Sans as DMSans } from 'next/font/google'
import Footer from './footer'
import Header from './Header'

const dmSans = DMSans({
  weight: ['700', '500', '400'],
  style: 'normal',
  subsets: ['latin'],
})

function Layout({ children }: { children: any }) {
  return (
    <>
      <div id='modal-portal' />
      <Header />
      <main className={dmSans.className}>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
