import * as React from 'react'
import classes from '@src/styles/Footer.module.css'
import dimensionLogo from '@src/assets/dimension-logo.svg'
import footerSchema from './constants'
// import { KustomerLauncher } from './KustomerLauncher'
// import { Kustomer } from '../helpers/kustomer'

export interface TrustPilotInterface {
  style?: string
}

const FooterLink = ({ item }: any) => {
  if (item.link) {
    const href = 
      // eslint-disable-next-line no-nested-ternary
      item.isRedirect ? 
      `${process.env.NEXT_PUBLIC_REDIRECT_URL}${item.link}` :
      item.isOutsideLink ? 
      item.link : 
      `/${item.link}`
    return (
      <a className={classes.hrefsColumnItem} href={href}>
        {item.label}
      </a>
    )
  }
  if (item.isChatLauncher) {
    const handleClick = () => {
      //   Kustomer.action(item.KustomerAction.action, item.KustomerAction.value)
    }
    // KustomerLauncher below
    return (
      <div onClick={item.KustomerAction ? handleClick : undefined}>
        <p className={classes.hrefsColumnItem}>{item.label}</p>
      </div>
    )
  }
  return <div className={classes.hrefsColumnItem} />
}

function Footer() {
  //   const profile = useSelector((state: any) => state.firebase?.profile)
  const profile = { userType: '' }
  const userType: any = ['provider', 'customer'].includes(profile.userType)
    ? profile.userType
    : 'default'
  const schema = footerSchema[userType]

  return (
    <section className={classes.container}>
      <div className={classes.mainContent}>
        <div className={classes.logoContainer} style={{ cursor: 'unset' }}>
          <img src={dimensionLogo.src} height='52' alt='Dimension Logo' />
          <span className={classes.hrefsColumnHeader}>Address</span>
          <p className={classes.addressText} style={{ margin: 0, padding: 0 }}>
            2261 Market Street, #4061
            <br />
            San Francisco, CA 94114
          </p>
        </div>
        <div className={classes.hrefsContainer}>
          {schema.map((column: any) => (
            <div className={classes.hrefsColumn} key={column.section}>
              <span className={classes.hrefsColumnHeader}>{column.section}</span>
              {column.links?.map((link: any) => (
                <FooterLink item={link} key={link.id ?? link.link} />
              ))}
            </div>
          ))}
        </div>
      </div>
      {/* <div className={classes.trustBox}>
        <TrustBox backgroundColor="#0c232d" isDark={false} />
      </div> */}
      <div className={classes.bottomContent}>
        <span className={classes.copyright}>© 2024 Dimension. All rights reserved.</span>
      </div>
    </section>
  )
}

export default Footer
