import Layout from '@src/components/layout';
import { setGclid } from '@src/functions/gclid';
import '@src/styles/globals.css';
import '@src/styles/Wordpress.css'
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React from 'react';


export default function App({ Component, pageProps }: AppProps) {
  const env = process.env.NEXT_PUBLIC_ENV;
  const router = useRouter();
  const { gclid } = router.query;

  setGclid(gclid as string);

  return (
    <>
      <Script id="data-layer" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({originalLocation: document.location.protocol + '//' + document.location.hostname + document.location.pathname + document.location.search});
        `}
      </Script>
      <Script id="segment" strategy="afterInteractive">
        {`
          !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${process.env.NEXT_PUBLIC_GOOGLE_SEGMENT_ID}";analytics.SNIPPET_VERSION="4.13.2";
          analytics.load("${process.env.NEXT_PUBLIC_GOOGLE_SEGMENT_ID}");
          analytics.page();
          analytics.track("App Load", { version: "next-js" });
          }}();
        `}
      </Script>
      {env === "production" && (
        <>
          <Script id="google-tag-manager" strategy="afterInteractive">
            {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');
            `}
          </Script>
          <Script id="kustomer" strategy="afterInteractive">
            {`
              window.addEventListener('kustomerLoaded', function() {
                Kustomer.start({
                  brandId: '${process.env.NEXT_PUBLIC_KUSTOMER_BRAND_ID}'
                });
              });
              var script = document.createElement('script');
              script.src = 'https://cdn.kustomerapp.com/chat-web/widget.js';
              script.setAttribute('data-kustomer-api-key', '${process.env.NEXT_PUBLIC_KUSTOMER_API_KEY}');
              window.document.body.appendChild(script);`
            }
          </Script>
          <Script id="trust-pilot" src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" strategy="afterInteractive" />
          <Script id="use-proof" src={`https://cdn.useproof.com/proof.js?acc=${process.env.NEXT_PUBLIC_USE_PROOF_ID}`} strategy="afterInteractive" />
        </>
      )}
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </>
  )
}
