export const getGclid = () => {
  if (typeof window !== "undefined") {
    const gclid = window.sessionStorage.getItem("next-gclid");
    if (!gclid) return "";
    return gclid;
  }
  return "";
}

export const setGclid = (gclid: string) => {
  if (typeof window !== "undefined" && gclid) {
    window.sessionStorage.setItem("next-gclid", gclid);
  }
}
