/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react'
import classes from '@src/styles/Header.module.css'
import { MdOutlinePowerSettingsNew } from 'react-icons/md'
import Link from 'next/link'
import dynamic from 'next/dist/shared/lib/dynamic'
import dimensionLogo from '@src/assets/dimension-logo.svg'
import dimensionLogoDark from '@src/assets/dimension-logo-dark.svg'
import accountIcon from '@src/assets/icons/user.svg'
import accountIconDark from '@src/assets/icons/user-dark.svg'
import MenuIcon from '@src/assets/menu-icon.svg'
import MenuIconDark from '@src/assets/menu-icon-dark.svg'
// import { roles } from '../../constants/roles'
// import LoginModal from '../LoginModal'
// import { signOut } from '../../store/actions/auth'
import useScrollPosition from '@src/hooks/useScrollPosition'
import { useRouter } from 'next/router'
import queryString from 'query-string'
import { useMediaQuery } from 'react-responsive'
import {
  aboutUsMenuItems,
  accountMenuItems,
  businessMenuItems,
  providersMenuItems,
  loggedInSupportItems,
  supportMenuItems,
  loggedInCustomerSupportMenuItems,
  aboutUsMenuItemsCustomer,
  sustainabilityItems,
  ourServicesMenuItems,
} from './constants'
import HeaderMenuItem from './HeaderMenuItem'

const MobileMenu = dynamic(() => import('./MobileMenu'))

const transparentRoutes = [
  '/',
  '/junk_removal',
  '/cardboard_removal',
  '/dumpster_rental',
  '/hazardous_waste',
  '/piano-removal',
  '/provider_portal',
  '/b2b-solutions',
  '/sustainability',
  '/pallet-disposal',
  '/about_us',
]

function Header({ opaque }: { opaque?: boolean }) {
  const history = useRouter()
  const { gclid, keyword, location } = history.query
  const isMobile = useMediaQuery({ query: '(max-width:768px)' })
  const [showEditBusiness] = useState(false)
  const [showIntegrations] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [isOpaque, setIsOpaque] = useState<boolean>(opaque ?? false)
  const [toggled, setToggled] = useState(null)

  const enableTransparent = transparentRoutes.includes(history.asPath)

  const subscribed = useRef(false)

  const isLoggedIn = false // useSelector((state: any) => state.firebase.auth?.uid)
  const profile = {
    uid: '1',
    email: '',
    userType: 'customer',
    approvalState: 'complete',
    isBanned: true,
    smsSettings: {
      address: {
        city: 'city',
      },
    },
  } // useSelector((state: any) => state.firebase.profile)
  const isProvider = profile?.userType === 'provider'
  const isApproved = !profile?.approvalState || profile?.approvalState === 'complete'
  const { isBanned } = profile
  const isBusinessAccount = false //! !useSelector((state: any) => state.firebase.profile.businessId)

  const scrollPosition = useScrollPosition()

  useEffect(() => {
    subscribed.current = true
    return () => {
      subscribed.current = false
    }
  }, [])

  const signOutMenuItem = {
    label: 'Sign Out',
    icon: <MdOutlinePowerSettingsNew color='primary' />,
    func: () => {},
    // func: () => dispatch(signOut()),
  }

  const toggleDrawer = (openDrawer: boolean) => (event: any) => {
    if (event?.type === 'keydown' && (event?.key === 'Tab' || event?.key === 'Shift')) {
      // eslint-disable-next-line no-useless-return
      return
    }
    setIsDrawerOpen(openDrawer)
  }

  useEffect(() => {
    if (isDrawerOpen && isMobile) {
      document.body.setAttribute('style', 'overflow-y: hidden;')
    } else {
      document.body.setAttribute('style', 'overflow-y: auto;')
    }
  }, [isDrawerOpen])

  useEffect(() => {
    const offset = isMobile ? 30 : 200
    setIsOpaque(opaque || !enableTransparent ? true : Math.round(scrollPosition / offset) > 0)
  }, [scrollPosition, opaque, isMobile, enableTransparent])

  const headerClasses = {
    container: isOpaque ? classes.containerOpaque : classes.container,
  }

  const handleLogin = () => {
    history.push(
      `${process.env.NEXT_PUBLIC_REDIRECT_URL}?`.concat(
        queryString.stringify({
          login: '1',
          gclid: gclid ?? '',
          redirect: '1',
        }),
      ),
    )
  }

  const itemsColorStyle = { color: isOpaque ? 'black' : 'white' }
  const menuIconProps: any = { style: itemsColorStyle }
  const primaryButtonClasses = isOpaque ? classes.primaryButton : classes.primaryButtonDark

  const sideLinks = isProvider
    ? isBanned
      ? accountMenuItems().providerSideNavLinks.filter((link: any) => link.url === '/account')
      : accountMenuItems(profile).providerSideNavLinks.filter((link) => !link.hidden)
    : isBusinessAccount
    ? accountMenuItems()
        .customerSideNavLinks.filter(
          (link) => (link.isBusiness && showEditBusiness) || !link.isBusiness,
        )
        .filter((link) => (link.isIntegrations && showIntegrations) || !link.isIntegrations)
    : accountMenuItems()
        .customerSideNavLinks.filter((link) => !link.isBusiness)
        .filter((link) => (link.isIntegrations && showIntegrations) || !link.isIntegrations)

  // Really need to refactor those conditions below! //
  return (
    <>
      {toggled && (
        <div
          className={classes.clickOutside}
          onClick={() => setToggled(null)}
          onMouseLeave={() => setToggled(null)}
        />
      )}
      <div className={headerClasses.container}>
        <div className={classes.contents}>
          <img
            className={classes.headerLogo}
            onClick={() => history.push('/')}
            style={{ cursor: 'pointer' }}
            src={isOpaque ? dimensionLogoDark.src : dimensionLogo.src}
            alt='Dimension Logo'
          />
          <div className={classes.headerButtons}>
            <div className={classes.menuContainer}>
              {!isLoggedIn && (
                <HeaderMenuItem
                  isOpaque={isOpaque}
                  name='Our Services'
                  menuItems={ourServicesMenuItems}
                  toggled={toggled}
                  setToggled={setToggled}
                  extended
                />
              )}
              {(!isLoggedIn || !isProvider) && (
                <HeaderMenuItem
                  isOpaque={isOpaque}
                  name='Business'
                  menuItems={businessMenuItems}
                  toggled={toggled}
                  setToggled={setToggled}
                />
              )}
              {!isLoggedIn && (
                <HeaderMenuItem
                  isOpaque={isOpaque}
                  name='Providers'
                  menuItems={providersMenuItems}
                  toggled={toggled}
                  setToggled={setToggled}
                />
              )}
              {(!isLoggedIn || !isProvider) && (
                <HeaderMenuItem
                  isOpaque={isOpaque}
                  name='Sustainability'
                  menuItems={sustainabilityItems}
                  toggled={toggled}
                  setToggled={setToggled}
                />
              )}
              {!isLoggedIn && (
                <HeaderMenuItem
                  isOpaque={isOpaque}
                  name='About us'
                  menuItems={aboutUsMenuItems}
                  toggled={toggled}
                  setToggled={setToggled}
                />
              )}
              {isLoggedIn && !isProvider && (
                <HeaderMenuItem
                  isOpaque={isOpaque}
                  name='About us'
                  menuItems={aboutUsMenuItemsCustomer}
                  toggled={toggled}
                  setToggled={setToggled}
                />
              )}
              {!isLoggedIn && (
                <HeaderMenuItem
                  isOpaque={isOpaque}
                  name='Support'
                  menuItems={supportMenuItems}
                  toggled={toggled}
                  setToggled={setToggled}
                />
              )}
              {isLoggedIn && !isProvider && (
                <HeaderMenuItem
                  isOpaque={isOpaque}
                  name='Support'
                  menuItems={loggedInCustomerSupportMenuItems}
                  toggled={toggled}
                  setToggled={setToggled}
                />
              )}
            </div>
            {isLoggedIn && isProvider && (
              <div className={classes.menuContainer}>
                <Link
                  href='/provider_portal'
                  style={itemsColorStyle}
                  className={classes.menuButton}
                >
                  Provider Portal
                </Link>
                <HeaderMenuItem
                  isOpaque={isOpaque}
                  name='Support'
                  menuItems={loggedInSupportItems}
                  toggled={toggled}
                  setToggled={setToggled}
                />
              </div>
            )}
            <div className={classes.buttonContainer}>
              {!isLoggedIn && (
                <button
                  onClick={handleLogin}
                  className={isOpaque ? classes.secondaryButton : classes.secondaryButtonDark}
                >
                  Log In
                </button>
              )}
              {isProvider && !isBanned && isApproved && (
                <>
                  <Link
                    href='/get_tasks'
                    style={{ width: '140px' }}
                    className={primaryButtonClasses}
                  >
                    Claim Tasks
                  </Link>
                  <Link
                    href='/get_bids'
                    style={{ width: '190px' }}
                    className={primaryButtonClasses}
                  >
                    Bid on Projects
                  </Link>
                </>
              )}
              {!isProvider && (
                <Link
                  href={`${process.env.NEXT_PUBLIC_REDIRECT_URL}booking?step=initial&gclid=${
                    gclid ?? ''
                  }&keyword=${keyword ?? ''}&location=${location ?? ''}&redirect=1`}
                  className={primaryButtonClasses}
                >
                  Book Now
                </Link>
              )}
              {isLoggedIn && (
                <HeaderMenuItem
                  isOpaque={isOpaque}
                  icon={isOpaque ? accountIconDark.src : accountIcon.src}
                  name='Account'
                  menuItems={[...sideLinks, signOutMenuItem]}
                  toggled={toggled}
                  setToggled={setToggled}
                />
              )}
            </div>
            <button
              className={classes.drawerButton}
              onClick={toggleDrawer(!isDrawerOpen)}
              style={{ paddingRight: 24 }}
            >
              <img
                src={isOpaque ? MenuIconDark.src : MenuIcon.src}
                {...menuIconProps}
                alt='Menu button'
              />
            </button>
          </div>
        </div>
        {isDrawerOpen && (
          <MobileMenu isOpaque={isOpaque} onClose={toggleDrawer(false)} handleLogin={handleLogin} />
        )}
        {/* <LoginModal open={open} closeModal={() => setOpen(false)} /> */}
      </div>
    </>
  )
}

export default Header
