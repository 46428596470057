/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react'
import classes from '@src/styles/Header.module.css'
import { MdOutlineArrowDropDown } from 'react-icons/md'
import Link from 'next/link'
import { useRouter } from 'next/router'
// import { KustomerLauncher } from '../KustomerLauncher'

function HeaderMenuItem({
  name,
  menuItems,
  isOpaque,
  icon,
  toggled,
  setToggled,
  extended = false,
}: {
  name: string
  menuItems: Array<any>
  isOpaque: boolean
  icon?: string
  toggled: string | null
  setToggled: any
  extended?: boolean
}) {
  const open = toggled === name

  const handleClick = (e: any) => {
    e.stopPropagation()
    setToggled(open ? null : name)
  }
  const handleClose = () => {
    setToggled(null)
  }

  const [hasSearchParams, setHasSearchParams] = useState(false)

  const history = useRouter()

  const searchParamsResponse = new URLSearchParams(history.asPath.split('?')[1]).toString()

  const searchParams = (hasQuery: boolean) => {
    if (hasSearchParams && hasQuery) {
      return `&${searchParamsResponse}`
    }
    if (hasSearchParams && !hasQuery) {
      return `?${searchParamsResponse}`
    }
    return ''
  }

  useEffect(() => {
    setHasSearchParams(searchParamsResponse !== '')
  }, [history.asPath])

  const hasQueryString = (url: string) => {
    try {
      const urlObj = new URL(url)
      return urlObj.search.length > 0
    } catch (error) {
      return false
    }
  }

  const getHeaderLinkUrl = (menuItem: any) => {
    if (menuItem?.link) {
      // eslint-disable-next-line prefer-destructuring
      let link = menuItem.link
      if (link[0] === '/') {
        link = menuItem.link.substring(1)
      }
      const hasQuery = hasQueryString(menuItem.link)
      const query = searchParams(hasQuery)
      if (menuItem.isOutsideLink) {
        return `${menuItem.link}${query}`
      }
      if (menuItem.isRedirect) {
        return `${process.env.NEXT_PUBLIC_REDIRECT_URL}${link}${query}`
      }
      return `${process.env.NEXT_PUBLIC_HOSTING_URL_PRIMARY}${link}${query}`
    }
    return ''
  }

  const Icon = ({ isMenuOpen }: { isMenuOpen?: boolean }) => {
    const openStyles = isMenuOpen ? { transform: 'rotate(-180deg)' } : {}
    return <MdOutlineArrowDropDown style={{ ...openStyles }} color={isOpaque ? 'black' : 'white'} />
  }

  const menuItemsByColumn = menuItems?.reduce((acc: any, item: any) => {
    if (item.column) {
      if (!acc[item.column]) {
        acc[item.column] = []
      }
      acc[item.column].push(item)
    }
    return acc
  }, {})

  return (
    <div style={{ position: 'relative' }}>
      <button
        className={icon ? classes.menuIconButton : classes.menuButton}
        style={{ color: isOpaque ? 'black' : 'white' }}
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {icon && <img alt='' src={icon} />}
        {name}
        <Icon isMenuOpen={open} />
      </button>
      <div
        id='basic-menu'
        className={classes.menuDropdown}
        style={{ display: !open ? 'none' : undefined }}
      >
        {!extended &&
          menuItems?.map((item) => (
            <div
              className={classes.menuItem}
              key={item.label}
              onClick={!item.link ? handleClose : undefined}
            >
              {item.icon && item.icon}
              <span style={{ marginLeft: item.icon ? '16px' : '0' }}>
                {/* span should be KustomerLauncher */}
                {!item.link ? (
                  item.label === 'Account' ? (
                    ''
                  ) : (
                    item.label
                  )
                ) : (
                  <Link
                    className={classes.menuItemLink}
                    href={getHeaderLinkUrl(item)}
                    target={item.isOutsideLink ? '_blank' : '_self'}
                    rel='noreferrer'
                    onClick={handleClose}
                  >
                    {item.label}
                  </Link>
                )}
              </span>
            </div>
          ))}

        {extended && (
          <div className={classes.extendedMenu}>
            {Object.keys(menuItemsByColumn).map((column) => (
              <div key={column}>
                {menuItemsByColumn[column].map((item: any) => (
                  <div className={classes.menuItemParent} key={item.label}>
                    <Link
                      className={classes.menuItem}
                      href={getHeaderLinkUrl(item)}
                      target={item.isOutsideLink ? '_blank' : '_self'}
                      rel='noreferrer'
                      onClick={handleClose}
                    >
                      {item.label}
                    </Link>
                    {item.subLinks && (
                      <div className={classes.menuSubLinks}>
                        {item.subLinks.map((subLink: any) => (
                          <Link
                            key={subLink.label}
                            className={classes.subMenuItem}
                            href={getHeaderLinkUrl(subLink)}
                            rel='noreferrer'
                            onClick={handleClose}
                          >
                            {subLink.label}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default HeaderMenuItem
